.sign-in-layout{
  height: 100vh;
  background: linear-gradient(275.9deg, #CCF3FF -0.16%, rgba(215, 255, 253, 0.16) 50.26%, #CBE9FF 99.48%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.signWarp{
  width: 468px;
  height: 406px;
  //border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  .signLogo{
    width: 200px;
    height: 50px;
    margin-bottom: 25px;
  }
  .signFrom{
    //border: 1px solid blue;
    width: 330px;
  }
}
