.logo {
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border: 10px solid blue;*/
}

.site-layout-background {
  //display: flex;
  //flex-direction: row-reverse;
}


.test1111 {
  flex: 1;
  font-size: 22px;
  font-weight: 900;
  color: whitesmoke;
  padding-left: 10px;
  /*border: 1px solid red;*/
}
